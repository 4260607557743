header {
  background-color: #2e2e2e;
  width: 100%;
  height: 180px;
  border-bottom: 2px solid #121212;
}

header h1 {
  color: white;
  opacity: 0.87;
  font-size: 50px;
}

header a {
  color: white;
  opacity: 0.87;
  text-decoration: none;
}

header a:hover {
  color: #efd105;
}

header nav {
  padding: 3%;
}