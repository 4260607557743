.aboutSitePage{
    display: flex;
    flex-direction: column;
}

/* Cp'd the styling form https://freebiesupply.com/blog/css-inputs/ */
.growing-search {
    padding: 5px 5px 5px 7px;
    border-radius: 5px;
    background: #fff;
  }
  
  .growing-search div {
    display: inline-block;
    font-size: 12px;
  }
  
  .growing-search .input input {
    margin-right: 0;
    border: none;
    font-size: inherit;
    transition: width 200ms;
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
    width: 230px;
    color: #2e2e2e;
    border-bottom: 1px solid #121212;
  }
  
  .growing-search .submit button {
    margin-left: 10%;
    border: none;
    font-size: 1.15em;
    color: #2e2e2e;
    background-color: #fff;
    padding-top: 2px;
    padding-bottom: 2px;
    transition: color 200ms;
  }
  
  .growing-search .input input:hover, .growing-search .submit button:hover {
    cursor: pointer;
  }
  
  .growing-search .input input:focus, .growing-search .submit button:focus {
    outline: none;
    border-bottom: 1px solid #efd105;
  }
  
  .growing-search .submit button:hover {
    color: #efd105;
  }