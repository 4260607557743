body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

header {
  background-color: #2e2e2e;
  width: 100%;
  height: 180px;
  border-bottom: 2px solid #121212;
}

header h1 {
  color: white;
  opacity: 0.87;
  font-size: 50px;
}

header a {
  color: white;
  opacity: 0.87;
  text-decoration: none;
}

header a:hover {
  color: #efd105;
}

header nav {
  padding: 3%;
}
.hexagon {
  overflow: hidden;
  visibility: hidden;
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}
.hexagon-in1 {
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(-60deg);
  transform: rotate(-60deg);
}
.hexagon-in2 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 20%;
  background-image: url(/static/media/headshot.c1462622.jpg);
  visibility: visible;
  -webkit-transform: rotate(-60deg);
  transform: rotate(-60deg);
}

.hexagon1 {
  width: 400px;
  height: 220px;
  margin: 0 0 0 -80px;
}

@media only screen and (max-width: 800px) {
  div.landing__body, div.aboutSitePage {
    width: 80%;
    margin: 0 auto;
  }

  .hexagon {
    margin: 0 auto;
  }
}

.introduction-content {
  padding: 0px 55px;
}
.surprise {
    display: inline-block;
}

.surprise img {
    max-width: 700px;
    padding: 5% 0;
}
.aboutSitePage{
    display: flex;
    flex-direction: column;
}

/* Cp'd the styling form https://freebiesupply.com/blog/css-inputs/ */
.growing-search {
    padding: 5px 5px 5px 7px;
    border-radius: 5px;
    background: #fff;
  }
  
  .growing-search div {
    display: inline-block;
    font-size: 12px;
  }
  
  .growing-search .input input {
    margin-right: 0;
    border: none;
    font-size: inherit;
    transition: width 200ms;
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
    width: 230px;
    color: #2e2e2e;
    border-bottom: 1px solid #121212;
  }
  
  .growing-search .submit button {
    margin-left: 10%;
    border: none;
    font-size: 1.15em;
    color: #2e2e2e;
    background-color: #fff;
    padding-top: 2px;
    padding-bottom: 2px;
    transition: color 200ms;
  }
  
  .growing-search .input input:hover, .growing-search .submit button:hover {
    cursor: pointer;
  }
  
  .growing-search .input input:focus, .growing-search .submit button:focus {
    outline: none;
    border-bottom: 1px solid #efd105;
  }
  
  .growing-search .submit button:hover {
    color: #efd105;
  }
