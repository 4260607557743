.hexagon {
  overflow: hidden;
  visibility: hidden;
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  -o-transform: rotate(120deg);
  transform: rotate(120deg);
}
.hexagon-in1 {
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(-60deg);
  -moz-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  -o-transform: rotate(-60deg);
  transform: rotate(-60deg);
}
.hexagon-in2 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 20%;
  background-image: url("../assets/headshot.jpg");
  visibility: visible;
  -webkit-transform: rotate(-60deg);
  -moz-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  -o-transform: rotate(-60deg);
  transform: rotate(-60deg);
}

.hexagon1 {
  width: 400px;
  height: 220px;
  margin: 0 0 0 -80px;
}

@media only screen and (max-width: 800px) {
  div.landing__body, div.aboutSitePage {
    width: 80%;
    margin: 0 auto;
  }

  .hexagon {
    margin: 0 auto;
  }
}

.introduction-content {
  padding: 0px 55px;
}